<template>
  <Container class="services__list">
    <div
      v-for="(item, index) in services"
      :key="index"
      :id="item.id"
      class="services__item"
      itemscope
      itemtype="http://schema.org/Product"
    >
      <div class="services__item-icon">
        <font-awesome-icon :icon="item.icon" />
      </div>
      <div class="services__item-content">
        <h2 itemprop="name">{{ item.title }}</h2>
        <p itemprop="description">{{ item.about }}</p>
      </div>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import { gsapFromTo } from '@/libs/animations';

import Container from '@/components/markup/ContainerComponent.vue';

import services from './services';

export default defineComponent({
  name: 'ServicesList',
  components: {
    Container,
  },
  setup() {
    onMounted(() => {
      const items = document.querySelectorAll('.services__item');
      items.forEach((item) => {
        gsapFromTo(item, { opacity: 0, y: 80 }, {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: item,
            start: 'top bottom',
            end: 'bottom 75%',
            scrub: true,
          },
        });
      });
    });

    return {
      services,
    };
  },
});
</script>

<style scoped>
.services__list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 20px;
  padding-top: 20px;
  padding-bottom: 80px;
}

.services__list > .services__item {
  display: flex;
  position: relative;
  align-items: center;
  --size: calc(25% - 15px);
  flex: 0 0 var(--size);
  width: var(--size);
  padding: 10px;
  border: 1px solid var(--dark-gray-blue);
  border-radius: 15px;
  transition: border-color .15s ease;
}

.services__list > .services__item > .services__item-icon {
  flex: .2;
}

.services__list > .services__item > .services__item-icon > svg {
  color: var(--blue);
}

.services__list > .services__item > .services__item-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: .8;
}

.services__list > .services__item > .services__item-content h2 {
  padding-bottom: 5px;
  font-size: 22px;
}

.services__list > .services__item > .services__item-content p {
  font-size: 18px;
}

.services__list > .services__item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 45%;
  background: var(--dark-gray-blue);
  transform: translateY(-50%);
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  transition: background .15s ease;
}

.services__list > .services__item::after {
  content: "";
  position: absolute;
  top: 68%;
  left: 1px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: var(--dark-gray);
  transform: translateY(-50%);
}

.services__list > .services__item:hover::before {
  background: var(--blue);
}

.services__list > .services__item:hover {
  border-color: var(--blue);
}

@media screen and (max-width: 1800px) {
  .services__list > .services__item > .services__item-content p {
    font-size: 14px;
  }

  .services__list > .services__item > .services__item-content h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1400px) {
  .services__list {
    justify-content: center;
  }

  .services__list > .services__item {
    --size: calc(33.33% - 15px);
  }

  .services__list > .services__item > .services__item-content h2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .services__list > .services__item {
    --size: calc(50% - 15px);
  }

  .services__list > .services__item > .services__item-icon {
    flex: .3;
    font-size: 36px;
  }
}

@media screen and (max-width: 700px) {
  .services__list > .services__item {
    --size: 100%;
  }
}
</style>
