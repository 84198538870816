<template>
  <div class="slider__item">
    <div class="slider__item-content">
      <h2>iOS / Android</h2>
      <Note class="bg--blue">От 300 000₽</Note>
      <p>Умеем и пратикуем разработку мобильных приложений<br>любой сложности «под ключ» с:</p>
      <p class="slider__section">
        <font-awesome-icon icon="gift" />
        <span>Бесплатной подготовкой технического задания<br>и фич-листа.</span>
      </p>
      <p class="slider__section">
        <font-awesome-icon icon="file-shield" />
        <span>Пожизненной гарантией на оказанные работы<br>и дальнейшей поддержкой.</span>
      </p>
      <p class="slider__section">
        <font-awesome-icon icon="laptop-code" />
        <span>Соблюдением сроков и максимально прозрачным<br><b>work-flow</b>.</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Note from '@/components/NoteComponent.vue';

export default defineComponent({
  name: 'MobileSlide',
  components: {
    Note,
  },
});
</script>
