<template>
  <Container class="preparation">
    <div class="preparation__left">
      <h3>Разработка технического задания в подарок</h3>
      <hr noshade>
      <p>
        Подготовим бриф, напишем техническое задание,
        составим фич-лист и смету работ всего <b>за 3 дня</b>
      </p>
    </div>
    <div class="preparation__right">
      <MaskedLink link="tel:89038417828">+7 (903) 841 78-28</MaskedLink>
      <p class="transition--disabled">или</p>
      <Button @click="showModal">Оставить заявку</Button>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import Container from '@/components/markup/ContainerComponent.vue';
import Button from '@/components/buttons/ButtonComponent.vue';
import MaskedLink from '@/components/MaskedLink.vue';

export default defineComponent({
  name: 'PreparationComponent',
  components: {
    Container,
    Button,
    MaskedLink,
  },
  setup() {
    const store = useStore();

    return {
      showModal: () => store.commit('SET_MODAL', true),
    };
  },
});
</script>

<style scoped>
.preparation {
  display: flex;
  align-items: center;
  gap: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--gray-blue);
  z-index: 1;
}

.preparation h3 {
  font-size: 24px;
}

.preparation hr {
  max-width: 70%;
  margin: 10px 0;
  border-color: var(--dark-gray-blue);
}

.preparation p {
  font-size: 20px;
}

.preparation > .preparation__left {
  flex: 2;
}

.preparation > .preparation__right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

@media screen and (max-width: 1800px) {
  .preparation > .preparation__left,
  .preparation > .preparation__right {
    flex: 1.5;
  }

  .preparation h3 {
    font-size: 20px;
  }

  .preparation p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .preparation > .preparation__left {
    flex: 2;
  }

  .preparation > .preparation__right {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }
}

@media screen and (max-width: 800px) {
  .preparation {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  .preparation hr {
    margin: 10px auto;
  }

  .preparation > .preparation__right {
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (max-width: 490px) {
  .preparation > .preparation__right {
    flex-direction: column;
    gap: 5px;
  }
}
</style>

<style>
.preparation .masked__link > a {
  color: var(--gray-blue);
  font-weight: 600;
}

.preparation .masked__link > .masked__link-overwrap {
  font-weight: 600;
}

@media screen and (max-width: 1400px) {
  .preparation > .preparation__right > button {
    margin-top: 10px;
  }
}
</style>
