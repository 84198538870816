<template>
  <div class="slider__item">
    <div class="slider__item-content">
      <h1>Jedels Tech.</h1>
      <p>
        Делаем технологии доступными и понятными<br><b>для всех</b>
      </p>
      <p class="slider__section">
        <font-awesome-icon icon="rocket" />
        <span>Создаём простые и эффективные<br>решения для вашего бизнеса.</span>
      </p>
      <div class="slider__contacts slider__transition">
        <Button @click="showModal">Оставить заявку</Button>
        <p class="transition--disabled slider__visible-large">или</p>
        <MaskedLink link="tel:89038417828" class="slider__visible-large">
          +7 (903) 841 78-28
        </MaskedLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import Button from '@/components/buttons/ButtonComponent.vue';
import MaskedLink from '@/components/MaskedLink.vue';

export default defineComponent({
  name: 'JedelsSlide',
  components: {
    Button,
    MaskedLink,
  },
  setup() {
    const store = useStore();

    return {
      showModal: () => store.commit('SET_MODAL', true),
    };
  },
});
</script>

<style scoped>
.slider__contacts {
  display: flex;
  align-items: center;
  gap: 20px;
}

.slider__contacts > p {
  font-size: 18px !important;
  margin: 0 !important;
}

@media screen and (max-width: 1800px) {
  .slider__contacts > p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 490px) {
  .slider__contacts {
    flex-direction: column;
    align-items: flex-start;
  }

  .slider__visible-large {
    display: none;
  }
}
</style>

<style>
.slider__contacts .masked__link > a {
  color: var(--blue);
}

.slider__contacts .masked__link {
  font-weight: 600;
}
</style>
