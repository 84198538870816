import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "portfolio" }
const _hoisted_2 = { id: "services" }
const _hoisted_3 = { id: "about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeComponent = _resolveComponent("HomeComponent")!
  const _component_PortfolioComponent = _resolveComponent("PortfolioComponent")!
  const _component_ServicesComponent = _resolveComponent("ServicesComponent")!
  const _component_AboutComponent = _resolveComponent("AboutComponent")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_HomeComponent),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_PortfolioComponent)
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_ServicesComponent)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_AboutComponent)
    ])
  ]))
}