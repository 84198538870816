import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da88565a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolio__item-image" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "portfolio__item-type" }
const _hoisted_4 = { class: "portfolio__item-content" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { class: "portfolio__cases" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cases, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "portfolio__item"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: item.image,
              alt: item.title
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(item.type), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", null, _toDisplayString(item.title), 1),
            _createElementVNode("div", {
              class: "portfolio__item-hover",
              innerHTML: item.about
            }, null, 8, _hoisted_5)
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}