<template>
  <!-- eslint-disable -->
  <svg height="100%" stroke-miterlimit="10" style="fill-rule:nonzero;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;" version="1.1" viewBox="0 0 2048 2048" width="100%" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:vectornator="http://vectornator.io" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs/>
    <g id="layer-1" vectornator:layerName="layer-1">
      <g opacity="1">
        <g opacity="1">
          <path d="M905.479 75.8058C897.226 75.7284 883.141 83.948 876.974 89.4321L732.015 217.628C725.847 223.112 714.633 229.987 706.94 232.977L493.024 316.113C485.331 319.103 476.66 327.72 473.63 335.397L401.048 519.298C398.018 526.975 392.771 539.251 389.294 546.737L292.661 754.86C289.184 762.346 287.18 775.051 288.218 783.239L314.285 988.868C315.322 997.056 315.434 1010.33 314.539 1018.54L298.427 1166.34C297.533 1174.55 301.546 1185.87 307.433 1191.66L401.537 1284.13C407.424 1289.92 413.173 1301.23 414.35 1309.4L456.939 1604.94C458.117 1613.11 465.011 1622.78 472.364 1626.53L798.542 1792.84C805.895 1796.59 818.29 1801.4 826.246 1803.6L962.445 1837.32C963.133 1837.51 963.994 1620.63 963.994 1620.63C963.994 1620.63 1029.3 1619.75 1091.09 1620.24L1091.09 1300.4C1090.85 1292.15 1093.19 1270.52 1097.79 1263.67C1097.79 1263.67 1192.56 1129.87 1192.75 1121.62L1192.75 647.622C1192.94 639.371 1192.64 613.591 1192.75 605.339L1192.75 180.729L1192.75 82.9624C1192.77 81.6286 1195.32 88.6039 1195.01 87.3703C1193.39 80.9711 1187.9 76.1077 1180.98 76.0428L905.479 75.8058Z" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="butt" stroke-linejoin="round" stroke-width="82.503"/>
          <path d="M962.379 1838.27L962.774 1967.54C1070.05 1967.55 1349.06 1972.21 1443.09 1972.21C1537.12 1972.22 1606.78 1931.45 1652.08 1849.95C1697.37 1768.45 1720.04 1640.89 1720.06 1467.25L1719.31 86.9372C1719.31 83.1024 1717.25 75.8737 1713.41 75.8058L1200.7 75.8058C1196.87 75.738 1169.78 75.6482 1169.77 79.483L1194.49 85.6046L1194.59 174.169L1193.26 447.301C1193.24 451.135 1196.3 454.31 1200.13 454.354L1425.78 455.595C1429.61 455.639 1432.73 458.773 1432.73 462.608L1432.68 1465.61C1432.68 1506.37 1427.54 1544.14 1411.08 1570.45C1394.65 1596.73 1366.19 1617.82 1336.91 1618.49C1285.55 1619.67 1183 1620.86 1076.22 1620.02" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="butt" stroke-linejoin="round" stroke-width="82.503"/>
        </g>
        <g opacity="1">
          <path d="M674.158 234.147C675.685 265.142 682.955 265.285 677.963 299.924C646.751 516.474 537.285 534.484 480.644 651.417" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M629.561 472.159C643.538 510.116 594.719 669.921 660.958 724.756" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M1074.08 296.751C990.207 361.584 918.051 290.137 857.952 325.361" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M1171.85 208.784C1118.73 211.496 1065.24 317.979 1022.19 387.988C968.055 476.031 953.248 480.291 993.887 587.406" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M401.906 1283.52C434.098 1263.54 520.906 1184.82 520.906 1184.82C520.906 1184.82 552.32 1158.96 562.334 1108.6C565.882 1090.76 554.694 1037.81 596.791 1000.53" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M555.605 1141.16C616.223 1107.1 663.437 1168.86 713.151 1184.74" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M641.797 1706.66C716.921 1634.15 701.537 1585.48 642.766 1505.35C624.453 1480.38 611.831 1465.98 586.185 1438.38" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M683.204 1569.1C682.046 1477 802.662 1430.99 874.905 1419.75C890.147 1417.37 930.139 1418.48 966.638 1432.53" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
          <path d="M974.989 1170.72C943.086 1108.87 844.547 1105.11 831.796 1023.22C807.168 865.048 1047.35 873.642 857.449 688.456" fill="none" opacity="1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="59.2211"/>
        </g>
      </g>
    </g>
  </svg>

</template>
