<template>
  <footer>
    <Container class="footer">
      <div class="footer__section footer__section-copyright footer__section-desktop">
        <LogoComponent />
        <p>Copyright 2021 - 2023<br>©️ все права защищены</p>
      </div>
      <div class="footer__section">
        <h3>Меню</h3>
        <router-link to="/#portfolio">Кейсы</router-link>
        <router-link to="/#services">Услуги</router-link>
        <router-link to="/#about">О нас</router-link>
        <router-link to="/#team">Команда</router-link>
        <router-link to="/blog">Блог</router-link>
      </div>
      <div class="footer__section">
        <h3>Услуги</h3>
        <router-link
          v-for="({ title, id }, index) in services"
          :key="index"
          :to="`/#${id}`"
        >{{ title }}</router-link>
      </div>
      <div class="footer__section footer__section-copyright footer__section-mobile">
        <LogoComponent />
        <p>Copyright 2021 - 2023<br>©️ все права защищены</p>
      </div>
      <div
        class="footer__section footer__section-contacts"
        itemscope
        itemtype="http://schema.org/Organization"
      >
        <h3>Контакты</h3>
        <meta itemprop="name" content="Jedels Tech.">
        <a href="tel:89038417828" itemprop="telephone">+ 7 (903) 841 78-28</a>
        <a href="mailto:partner@jedels.com" itemprop="email">partner@jedels.com</a>
      </div>
      <a class="footer__scroller" href="#">↑</a>
    </Container>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import LogoComponent from '@/components/graphics/LogoComponent.vue';

import Container from '@/components/markup/ContainerComponent.vue';

import services from '@/components/services/services';

export default defineComponent({
  name: 'FooterComponent',
  components: {
    Container,
    LogoComponent,
  },
  setup() {
    return { services };
  },
});
</script>

<style scoped>
.footer {
  display: flex;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.footer > .footer__section {
  flex: 1;
}

.footer > .footer__section.footer__section-copyright {
  padding-top: 8px;
  flex: 2;
}

.footer > .footer__section.footer__section-copyright > p {
  margin-top: 40px;
}

.footer > .footer__section > h3 {
  font-size: 28px;
  margin-bottom: 20px;
}

.footer > .footer__section > a {
  display: block;
  position: relative;
  width: fit-content;
  font-size: 20px;
  color: lightgray;
  transition: color .15s ease;
}

.footer > .footer__section.footer__section-contacts > a {
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.footer > .footer__section > a:hover {
  color: var(--blue);
}

.footer > .footer__section > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--blue);
  opacity: 0;
  transition: opacity .15s ease;
}

.footer > .footer__section > a:hover::after {
  opacity: 1;
}

.footer > .footer__section > p {
  font-size: 16px;
}

.footer > .footer__scroller {
  --size: 80px;
  position: absolute;
  display: block;
  color: white;
  left: 50%;
  bottom: calc(var(--size) / 2 * -1);
  transform: translateX(-50%);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: var(--dark-gray-blue);
  font-size: 40px;
  text-align: center;
  cursor: pointer;
  transition: opacity .15s ease;
}

.footer > .footer__scroller:hover {
  opacity: .9;
}

.footer > .footer__section-mobile {
  display: none;
}

@media screen and (max-width: 1800px) {
  .footer > .footer__section > h3 {
    font-size: 20px;
  }

  .footer > .footer__section > a {
    font-size: 16px;
  }

  .footer > .footer__section.footer__section-contacts > a {
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    flex-wrap: wrap;
    row-gap: 50px;
  }

  .footer > .footer__section {
    flex: 0 0 50%;
  }

  .footer > .footer__section-desktop {
    display: none;
  }

  .footer > .footer__section-mobile {
    display: block;
  }

  .footer > .footer__section.footer__section-copyright > p {
    margin-top: 20px;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer > .footer__section {
    flex: 1;
    max-width: 300px;
    width: 100%;
  }
}
</style>
