<template>
  <Container :fullWidth="true" class="portfolio">
    <Container :fullWidth="true" class="portfolio__inner">
      <Container>
        <div class="portfolio__inner-left">
          <h2 class="title">// Наши кейсы</h2>
          <h3>Лучшие сервисы, которые мы разрабатываем и поддерживаем</h3>
        </div>
        <div class="portfolio__inner-right">
          <p>
            Предлагаем вам ознакомиться с теми сервисами,
            которые уже решают сложные задачи и улучшают бизнес-процессы наших клиентов
          </p>
        </div>
      </Container>
    </Container>
    <PortfolioCases/>
  </Container>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import { gsapFromTo } from '@/libs/animations';

import Container from '../markup/ContainerComponent.vue';

import PortfolioCases from './PortfolioCases.vue';

export default defineComponent({
  name: 'PortfolioComponent',
  components: {
    Container,
    PortfolioCases,
  },
  setup() {
    onMounted(() => {
      gsapFromTo('.portfolio__inner-left > .title', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.portfolio__inner-left > .title',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.portfolio__inner-left > h3', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.portfolio__inner-left > h3',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.portfolio__inner-right > p', { opacity: 0, x: 100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.portfolio__inner-right > p',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });
    });
  },
});
</script>

<style scoped>
.portfolio {
  margin-top: 40px;
}

.portfolio .portfolio__inner {
  background-color: var(--dark-gray);
  padding-bottom: 300px !important;
}

.portfolio > .portfolio__inner > .container {
  display: flex;
  align-items: center;
  padding: 80px 0;
}

.portfolio .portfolio__inner-left, .portfolio .portfolio__inner-right {
  flex: 1;
}

.portfolio .portfolio__inner-left {
  text-align: right;
  padding-right: 100px;
  border-right: 2px solid var(--dark-gray-blue);
}

.portfolio .portfolio__inner-right {
  padding-left: 100px;
}

.portfolio .portfolio__inner-left > h3 {
  font-size: 36px;
  line-height: 42px;
  margin-top: 20px;
}

.portfolio .portfolio__inner-right > p {
  font-size: 20px;
}

@media screen and (max-width: 1400px) {
  .portfolio .portfolio__inner-left {
    padding-right: 70px;
  }

  .portfolio .portfolio__inner-right {
    padding-left: 70px;
  }

  .portfolio .portfolio__inner-left > h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .portfolio .portfolio__inner-right > p {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .portfolio .portfolio__inner {
    padding-bottom: 150px !important;
  }

  .portfolio > .portfolio__inner > .container {
    flex-direction: column;
  }

  .portfolio > .portfolio__inner > .container {
    padding: 60px var(--padding-x);
  }

  .portfolio .portfolio__inner-left,
  .portfolio .portfolio__inner-right {
    padding: 0;
    text-align: center;
  }

  .portfolio .portfolio__inner-left {
    border-right: 0;
    border-bottom: 2px solid var(--dark-gray-blue);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}
</style>
