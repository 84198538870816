import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7191f266"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "services__item-icon" }
const _hoisted_3 = { class: "services__item-content" }
const _hoisted_4 = { itemprop: "name" }
const _hoisted_5 = { itemprop: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { class: "services__list" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          id: item.id,
          class: "services__item",
          itemscope: "",
          itemtype: "http://schema.org/Product"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_font_awesome_icon, {
              icon: item.icon
            }, null, 8, ["icon"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(item.title), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(item.about), 1)
          ])
        ], 8, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }))
}