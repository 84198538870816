<template>
  <header>
    <Container>
      <LogoComponent />

      <Button
        :square="true"
        :simpleBorder="true"
        class="header__burger"
        :class="{'active': isMenuOpened}"
        @click="toggleMenu"
      >
        <div class="header__burger-button"></div>
      </Button>

      <div class="header__buttons" :class="{'active': isMenuOpened}">
        <nav>
          <router-link to="/#portfolio">Кейсы</router-link>
          <router-link to="/#services">Услуги</router-link>
          <router-link to="/#about">О нас</router-link>
          <router-link to="/#team">Команда</router-link>
          <router-link to="/blog">Блог</router-link>
        </nav>

        <div class="actions">
          <Button :square="true" :simpleBorder="true" :href="'tel:89038417828'">
            <font-awesome-icon icon="phone" />
          </Button>
          <Button :square="true" :simpleBorder="true" :href="'https://t.me/DenBro'" target="_blank">
            <font-awesome-icon icon="paper-plane" />
          </Button>
          <Button
            :simpleBorder="true"
            class="actions__contact"
            @click="showModal"
          >СВЯЗАТЬСЯ С НАМИ</Button>
        </div>
      </div>
    </Container>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import Container from '@/components/markup/ContainerComponent.vue';
import Button from '@/components/buttons/ButtonComponent.vue';

import LogoComponent from '@/components/graphics/LogoComponent.vue';

export default defineComponent({
  components: {
    Container,
    Button,
    LogoComponent,
  },
  setup() {
    const store = useStore();
    const isMenuOpened = ref(false);

    return {
      showModal: () => store.commit('SET_MODAL', true),
      toggleMenu: () => {
        isMenuOpened.value = !isMenuOpened.value;
      },
      isMenuOpened,
    };
  },
});
</script>

<style>
header {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
}

header > .container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  transition: .15s ease;
}

header.active > .container {
  margin-top: 15px;
  background: var(--dark-gray-blue);
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

header > .container > .logotype {
  flex: 1;
  padding: 5px 0;
}

header .header__buttons {
  display: flex;
  flex: 3;
  justify-content: space-between;
  align-items: center;
}

header nav {
  display: flex;
  justify-content: flex-end;
  flex: 2;
  font-size: 20px;
  gap: 30px;
}

header nav > a {
  width: fit-content;
  position: relative;
  color: white;
  transition: .15s ease;
}

header nav > a::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  height: 1px;
  width: 100%;
  background: var(--blue);
  opacity: 0;
}

header nav > a:hover {
  color: var(--blue);
}

header nav > a:hover::after {
  opacity: 1;
}

header .actions {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

header .actions > .actions__contact {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
}

header .header__burger {
  display: none !important;
  height: 35px;
  width: 35px;
}

header .header__burger-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  height: 2px;
  border-radius: 2px;
  background: white;
  transition: transform .25s ease-in-out;
}

header .header__burger-button::before,
header .header__burger-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background: white;
  transition: .25s ease-in-out;
}

header .header__burger-button::before {
  transform-origin: center;
  top: -300%;
}

header .header__burger-button::after {
  transform-origin: center;
  top: 300%;
}

header .header__burger.active .header__burger-button {
  background: rgba(0, 0, 0, 0);
}

header .header__burger.active .header__burger-button::before {
  top: 0;
  transform: translateX(-50%) rotate(45deg);
}

header .header__burger.active .header__burger-button::after {
  top: 0;
  transform: translateX(-50%) rotate(-45deg);
}

@media screen and (max-width: 1800px) {
  header nav {
    font-size: 16px;
    gap: 25px;
  }

  header .actions > .actions__contact {
    font-size: 15px;
  }

  header nav,
  header .actions {
    flex: 1.5;
  }
}

@media screen and (max-width: 1000px) {
  header .header__burger {
    display: block !important;
    padding: 10px;
  }

  header .header__buttons {
    display: none;
    position: absolute;
    top: calc(100% + 15px);
    left: 0;
    height: auto;
    width: 100%;
    z-index: 9;
    background: var(--dark-gray-blue);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
  }

  header .header__buttons.active {
    display: block;
  }

  header nav {
    flex-direction: column;
  }

  header .actions {
    margin-top: 25px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 800px) {
  header {
    padding: 0 var(--padding-x);
  }

  header .actions svg {
    width: auto;
  }
}
</style>
