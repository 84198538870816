<template>
  <div class="wrapper">
    <HeaderComponent />
    <div class="content">
      <router-view />
      <FooterComponent />
    </div>
    <ModalComponent />
    <Notifications position="bottom right" classes="jedels-notify" width="500" />
  </div>
</template>

<script lang="ts">
import HeaderComponent from '@/components/header/MenuComponent.vue';
import FooterComponent from './components/footer/FooterComponent.vue';
import ModalComponent from './components/modal/ModalComponent.vue';

export default {
  components: {
    FooterComponent,
    HeaderComponent,
    ModalComponent,
  },
};
</script>

<style>
/* VARIABLES */

:root {
  --customfont: 'Rubik', sans-serif;
  --systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  --font-size: calc(1vw + 1vh);
  --line-height: 1.5;
  --padding-x: 1.5rem;
  --transition: .75s cubic-bezier(.2, .6, 0, 1);
  --blue: #508BCB;
  --gray-blue: #6F7A8B;
  --dark-gray-blue: #2D3038;
  --dark-gray: #202328;
}

/* FONTS SECTION */

@font-face {
  src: url("@/fonts/Rubik-Black.ttf") format("truetype");
  font-family: 'Rubik';
  font-weight: 900;
}

@font-face {
  src: url("@/fonts/Rubik-Bold.ttf") format("truetype");
  font-family: 'Rubik';
  font-weight: 700;
}

@font-face {
  src: url("@/fonts/Rubik-Regular.ttf") format("truetype");
  font-family: 'Rubik';
  font-weight: 400;
}

@font-face {
  src: url("@/fonts/Rubik-Light.ttf") format("truetype");
  font-family: 'Rubik';
  font-weight: 300;
}

/* GENERAL CSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: var(--customfont);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 400;
  overflow-x: hidden;

  background-color: #171a1a;
  color: white;
}

html.modal--opened,
html.modal--opened body,
html.modal--opened .content,
html.modal--opened .wrapper,
html.modal--opened #app {
  overflow: hidden !important;
}

svg {
  height: 95%;
  width: 95%;
}

a {
  text-decoration: none;
}

.bg--blue {
  background-color: var(--blue) !important;
}

button {
  background: none;
  border: none;
}

.title {
  color: var(--blue);
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.jedels-notify {
  width: calc(100% - 20px) !important;
  padding: 20px !important;
  margin: 10px !important;
  border-radius: 15px !important;
  background: var(--gray-blue) !important;
  cursor: pointer !important;
}

.jedels-notify.error {
  background: #8b6f6f !important;
}

.jedels-notify > .notification-title {
  font-size: 24px !important;
}

.jedels-notify > .notification-content {
  font-size: 18px !important;
}
</style>
