<template>
  <div class="slider" data-speed=".8">
    <Container>
      <div class="slider__items">
        <transition
          name="text"
          mode="out-in"
        >
          <component v-bind:is="slides[activeSlideIndex]" />
        </transition>
      </div>
      <PhoneComponent />
    </Container>
    <div class="slider__controller" v-if="showControllerPanel">
      <button
        v-for="index in slides.length"
        :key="index"
        class="slider__button"
        :class="{active: index == activeSlideIndex + 1}"
        @click="controllerMove(index - 1)"
      >
        <span>{{ index }}</span>
        <div class="outline"></div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

import { gsapFromTo } from '@/libs/animations';

import Container from '@/components/markup/ContainerComponent.vue';

import PhoneComponent from '@/components/graphics/PhoneComponent.vue';

import JedelsSlide from './slides/JedelsSlide.vue';
import WebsiteSlide from './slides/WebsiteSlide.vue';
import MobileSlide from './slides/MobileSlide.vue';

export default defineComponent({
  name: 'SliderComponent',
  props: {
    controller: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: 10000,
    },
    isAutoplay: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Container,
    PhoneComponent,
  },
  setup(props) {
    const activeSlideIndex = ref(0);
    const showControllerPanel = ref(props.controller);
    const slides = [JedelsSlide, WebsiteSlide, MobileSlide];
    let interval: number | undefined;

    const moveToIndex = (index: number) => {
      activeSlideIndex.value = index;
    };

    const moveSlider = () => {
      let nextSlideIndex = activeSlideIndex.value + 1;

      if (nextSlideIndex >= slides.length) {
        nextSlideIndex = 0;
      }

      moveToIndex(nextSlideIndex);
    };

    const autoplay = () => {
      if (!props.isAutoplay) {
        return;
      }

      if (!interval) {
        interval = setInterval(() => {
          moveSlider();
        }, props.interval);
      }
    };

    const controllerMove = (index: number) => {
      moveToIndex(index);

      if (interval) {
        clearInterval(interval);
      }

      autoplay();
    };

    onMounted(() => {
      gsapFromTo('.slider', { opacity: 1, y: 0 }, {
        opacity: 0,
        y: 80,
        scrollTrigger: {
          trigger: '.slider',
          start: '15% top',
          end: '70% top',
          scrub: true,
        },
      });
      autoplay();
    });

    return {
      activeSlideIndex,
      slides,
      showControllerPanel,
      controllerMove,
    };
  },
});
</script>

<style>
.slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.slider > .container {
  position: relative;
  width: 100%;
  height: 100%;
}

.slider .slider__items {
  width: 100%;
  height: 100%;
}

.slider .notebook {
  align-self: center;
}

.slider .slider__items > .slider__item {
  position: relative;
  width: 100%;
  height: 100%;
}

.slider .slider__items > .slider__item > .slider__item-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 50%;
}

.slider .slider__items > .slider__item h2,
.slider .slider__items > .slider__item h1 {
  color: var(--blue);
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
}

.slider .slider__items > .slider__item p {
  margin: 40px 0;
  font-size: 24px;
  line-height: 24px;
}

.slider .slider__items > .slider__item .note {
  font-size: 24px;
}

.slider .slider__items > .slider__item .bg--blue {
  margin-top: 30px;
}

.slider > .slider__controller {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: -40px;
  top: 50%;
  width: 100px;
  font-size: 18px;
  transform: translateX(-50%);
}

.slider > .slider__controller > .slider__button {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  cursor: pointer;
  width: 80%;
  transition: .4s ease;
}

.slider > .slider__controller > .slider__button > span {
  color: gray;
  transition: .4s ease;
}

.slider > .slider__controller > .slider__button > .outline {
  height: 1px;
  width: 100%;
  background: gray;
  transition: .4s ease;
}

.slider > .slider__controller > .slider__button.active {
  width: 100%;
}

.slider > .slider__controller > .slider__button.active > span {
  color: white;
  font-size: 24px;
}

.slider > .slider__controller > .slider__button.active > .outline {
  background: white;
}

.slider > .slider__controller > .slider__button:hover > span {
  color: white;
}

.slider > .slider__controller > .slider__button:hover > .outline {
  background: white;
}

.slider .slider__section {
  display: flex;
  align-items: center;
}

.slider .slider__section > svg {
  height: 50px;
  width: 50px;
  margin-right: 40px;
}

.text-enter-active,
.text-leave-active {
  transition: opacity .5s ease;
}

.text-enter-from,
.text-leave-to {
  opacity: 0;
}

.text-enter-active h2,
.text-enter-active h1 {
  animation: text-rotate-title-in .5s;
}

.text-leave-active h2,
.text-leave-active h1 {
  animation: text-rotate-title-out .5s;
}

.text-enter-active .bg--blue, .text-enter-active p:not(.transition--disabled) {
  animation: text-rotate-content-in .6s;
}

.text-leave-active .bg--blue, .text-leave-active p:not(.transition--disabled) {
  animation: text-rotate-content-out .6s;
}

.text-enter-active .note:not(.bg--blue), .text-enter-active .slider__transition {
  animation: text-rotate-content-in .7s;
}

.text-leave-active .note:not(.bg--blue), .text-leave-active .slider__transition {
  animation: text-rotate-content-out .7s;
}

@keyframes text-rotate-title-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes text-rotate-title-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-60px);
    opacity: 0;
  }
}

@keyframes text-rotate-content-in {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes text-rotate-content-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-40px);
    opacity: 0;
  }
}

@media screen and (max-width: 1800px) {
  .slider .slider__items > .slider__item h2,
  .slider .slider__items > .slider__item h1 {
    font-size: 36px;
    line-height: 36px;
  }

  .slider .slider__items > .slider__item p {
    font-size: 18px;
    margin: 30px 0;
    line-height: 18px;
  }

  .slider .slider__section > svg {
    height: 40px;
    width: 40px;
    margin-right: 25px;
  }

  .slider .slider__items > .slider__item .note {
    font-size: 18px;
  }

  .slider .slider__items > .slider__item .bg--blue {
    margin-top: 25px;
  }
}

@media screen and (max-width: 1400px) {
  .slider {
    padding-top: 60px;
  }
}

@media screen and (max-width: 1000px) {
  .slider .slider__items > .slider__item > .slider__item-content {
    max-width: 75%;
  }
}

@media screen and (max-width: 800px) {
  .slider > .slider__controller {
    display: none;
  }

  .slider .slider__items > .slider__item > .slider__item-content {
    max-width: 100%;
  }
}
</style>
