import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!
  const _component_PreparationComponent = _resolveComponent("PreparationComponent")!
  const _component_HeaderBackground = _resolveComponent("HeaderBackground")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, {
    fullScreen: true,
    flex: true,
    class: "home-screen"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Slider, { interval: 8500 }),
      _createVNode(_component_PreparationComponent),
      _createVNode(_component_HeaderBackground)
    ]),
    _: 1
  }))
}