import gsap from '@/libs/gsap/gsap.min';
import { ScrollTrigger } from '@/libs/gsap/ScrollTrigger.min';
import { ScrollSmoother } from '@/libs/gsap/ScrollSmoother.min';
import { ScrollToPlugin } from '@/libs/gsap/ScrollToPlugin.min';

import { setupAnimations } from '@/libs/animations';

const safariAgent = /constructor/i.test(window.HTMLElement) || (
  function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  }(
    !window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification),
  ));

export default () => {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

  if (ScrollTrigger.isTouch !== 1 && !safariAgent) {
    ScrollSmoother.create({
      wrapper: '#app',
      content: '.content',
      smooth: 1.5,
      effects: true,
    });
  }

  document.querySelectorAll('a').forEach((link) => {
    link.addEventListener('click', (event) => {
      let scrollToHere = link.getAttribute('href');
      if (scrollToHere.startsWith('#') || scrollToHere.startsWith('/#')) {
        event.preventDefault();
        if (scrollToHere === '#') {
          scrollToHere = 'body';
        }
        scrollToHere = scrollToHere.replace('/', '');
        gsap.to(window, { duration: 0.75, scrollTo: scrollToHere });
      }
    });
  });

  setupAnimations();
};
