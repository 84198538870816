<template>
  <div class="modal modal--feedback" v-if="isModalShown">
    <div class="modal__content">
      <button class="modal__close" @click="close"></button>
      <div class="modal__content-left">
        <img src="@/assets/modal/modal-bg.jpg" alt="Background">
      </div>
      <div class="modal__content-right">
        <h2>Оставьте заявку</h2>
        <p>а мы с вами свяжемся в течение дня</p>
        <label for="modal-name">
          <input type="text" id="modal-name" placeholder="Имя" v-model="state.name">
        </label>
        <label for="modal-phone">
          <input
            id="masked-input"
            v-maska
            data-maska="+7 (###) ###-##-##"
            type="text"
            placeholder="Номер телефона"
            v-model="state.phone"
          >
        </label>
        <label for="modal-email">
          <input type="text" id="modal-email" placeholder="Email" v-model="state.email">
        </label>
        <label for="modal-content">
          <textarea
            id="modal-content"
            placeholder="Опишите вашу идею"
            v-model="state.content"
          ></textarea>
        </label>
        <label for="modal-agreement" class="agreement">
          <input type="checkbox" id="modal-agreement" v-model="state.agreement">
          <div class="checkbox"></div>
          <router-link to="/agreement" target="_blank">
            Нажимая кнопку «Отправить», я даю свое согласие на обработку моих
            персональных данных, в соответствии с Федеральным законом от 27.07.2006
            года №152-ФЗ «О персональных данных», на условиях и для целей,
            определенных в Согласии на обработку персональных данных
          </router-link>
        </label>
        <Button
          :simpleBorder="true"
          :loading="state.loading"
          :active="(state.name && state.phone && state.email && state.agreement) ? true : false"
          @click="sendFeedback"
        >Отправить</Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive } from 'vue';
import { useStore } from 'vuex';

import { vMaska } from 'maska';
import { notify } from '@kyvg/vue3-notification';

import * as feedback from '@/rest-api/feedback';

import Button from '@/components/buttons/ButtonComponent.vue';

export default defineComponent({
  components: {
    Button,
  },
  directives: {
    maska: vMaska,
  },
  setup() {
    const store = useStore();

    const state = reactive({
      loading: false,
      name: '',
      phone: '',
      email: '',
      content: '',
      agreement: false,
    });

    const sendFeedback = () => {
      if (state.name && state.phone && state.email && state.agreement) {
        state.loading = true;
        feedback.create(state.name, state.phone, state.email, state.content).then((payload) => {
          if (payload) {
            notify({
              title: 'Спасибо',
              text: 'Мы записали данные и в ближайшее время свяжемся с вами.',
              duration: 7500,
            });
          }
          setTimeout(() => {
            state.loading = false;
          }, 500);
        });
      }
    };

    return {
      isModalShown: computed(() => store.state.modal),
      close: () => store.commit('SET_MODAL', false),
      sendFeedback,
      state,
    };
  },
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .65);
  z-index: 100;
}

.modal > .modal__content {
  display: flex;
  position: absolute;
  width: 1720px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark-gray-blue);
  border-radius: 15px;
  overflow: hidden;
}

.modal > .modal__content > .modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  z-index: 1;
  cursor: pointer;
}

.modal > .modal__content > .modal__close::after {
  content: "\00d7";
  font-size: 42px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  color: white;
  transition: color .15s ease;
}

.modal > .modal__content > .modal__close:hover::after {
  color: var(--blue);
}

.modal > .modal__content > .modal__content-right,
.modal > .modal__content > .modal__content-left {
  position: relative;
  flex: 1 1 50%;
  overflow: hidden;
}

.modal > .modal__content > .modal__content-right {
  padding: 30px 120px;
  text-align: center;
}

.modal > .modal__content > .modal__content-right > h2 {
  font-size: 36px;
  line-height: 32px;
  color: var(--blue);
  margin-bottom: 10px;
}

.modal > .modal__content > .modal__content-right > p {
  font-size: 20px;
  line-height: 18px;
}

.modal > .modal__content img {
  top: 0;
  left: 0;
  position: absolute;
  min-width: 100%;
  height: 100%;
}

.modal input[type="text"], .modal textarea {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin-top: 30px;
  font-size: 18px;
  padding: 15px 20px;
  background: var(--dark-gray);
  border: none;
  border-radius: 10px;
  color: white;
  font-family: monospace;
}

.modal textarea {
  height: 110px;
  max-height: 110px;
  min-height: 110px;
  margin-bottom: 30px;
}

.modal .agreement {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 14px;
  text-align: left;
  margin-bottom: 30px;
  padding-left: 40px;
  padding-top: 1px;
}

.modal .agreement > a {
  color: var(--gray-blue);
  font-size: 14px;
  line-height: 16px;
}

.modal input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.modal .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background: var(--dark-gray);
  border-radius: 5px;
}

input[type="checkbox"]:checked ~ .checkbox::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 4px;
  width: 6px;
  height: 13px;
  border: solid #ffffff;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}

input[type="checkbox"]:checked ~ .checkbox {
  background: var(--blue);
}

.modal input[type="text"]::placeholder, .modal textarea::placeholder {
  color: var(--gray-blue);
}

@media screen and (max-width: 1800px) {
  .modal > .modal__content {
    width: 1320px;
  }

  .modal > .modal__content > .modal__content-right {
    padding: 25px 80px;
  }

  .modal > .modal__content > .modal__content-right > h2 {
    font-size: 32px;
    line-height: 30px;
  }

  .modal > .modal__content > .modal__content-right > p {
    font-size: 18px;
    line-height: 16px;
  }

  .modal input[type="text"], .modal textarea {
    margin-top: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .modal > .modal__content {
    width: 960px;
  }

  .modal > .modal__content > .modal__content-right {
    padding: 25px 40px;
  }
}

@media screen and (max-width: 1000px) {
  .modal > .modal__content {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }

  .modal > .modal__content > .modal__content-left {
    display: none;
  }
}

@media screen and (max-width: 490px) {
  .modal > .modal__content > .modal__content-right > h2 {
    font-size: 26px;
    line-height: 26px;
  }

  .modal input[type="text"], .modal textarea {
    margin-top: 15px;
  }

  .modal textarea,
  .modal .agreement {
    margin-bottom: 15px;
  }
}
</style>
