import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38406721"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSpinner = _resolveComponent("VueSpinner")!

  return (!_ctx.href)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass({'square': _ctx.square, 'simple-border': _ctx.simpleBorder}),
        disabled: !_ctx.active
      }, [
        (!_ctx.loading)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
          : (_openBlock(), _createBlock(_component_VueSpinner, { key: 1 }))
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: _ctx.href,
        class: _normalizeClass(["button", {'square': _ctx.square, 'simple-border': _ctx.simpleBorder}])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 10, _hoisted_2))
}