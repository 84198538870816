import gsap from '@/libs/gsap/gsap.min';
import { ScrollTrigger } from '@/libs/gsap/ScrollTrigger.min';

const animations = [];

export const refresh = () => {
  ScrollTrigger.refresh();
};

export const setupAnimations = () => {
  const header = document.querySelector('header');
  let isHeaderAttached = false;

  window.onscroll = () => {
    if (window.scrollY >= 50 && !isHeaderAttached) {
      header.classList.add('active');
      isHeaderAttached = true;
    } else if (window.scrollY < 50 && isHeaderAttached) {
      header.classList.remove('active');
      isHeaderAttached = false;
    }
  };
};

export const gsapFromTo = (element, start, end) => {
  animations.push(gsap.fromTo(element, start, end));
};
