import { createApp } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPhone,
  faRocket,
  faWrench,
  faFingerprint,
  faGaugeHigh,
  faGift,
  faFileShield,
  faLaptopCode,
  faGlobe,
  faMobile,
  faCubes,
  faRobot,
  faPalette,
  faBriefcase,
  faHandshake,
  faPaperPlane,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

import Notifications from '@kyvg/vue3-notification';

import setupGsap from './gsap';

import App from './App.vue';
import router from './router';
import store from './store';

library.add(
  faPhone,
  faRocket,
  faWrench,
  faFingerprint,
  faGaugeHigh,
  faGift,
  faFileShield,
  faLaptopCode,
  faGlobe,
  faMobile,
  faCubes,
  faRobot,
  faPalette,
  faBriefcase,
  faHandshake,
  faPaperPlane,
  faBars,
);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(store).use(router).use(Notifications);

app.mount('#app');

setupGsap();
