<template>
  <span class="note">
    <slot></slot>
  </span>
</template>

<style scoped>
.note {
  display: block;
  width: fit-content;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 8px 15px;
  background-color: rgba(80, 80, 80, .4);
  font-size: calc(var(--font-size) * .8);
  line-height: var(--font-size);
}
</style>
