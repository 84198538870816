export default [
  {
    name: 'Денис Шурыгин',
    avatar: require('@/assets/team/ceo_denis.jpg'), // eslint-disable-line
    post: 'CEO / FullStack',
  },
  {
    name: 'Илья Борзых',
    avatar: require('@/assets/team/ilya.jpg'), // eslint-disable-line
    post: 'CTO / FullStack',
  },
  {
    name: 'Максим Мотин',
    avatar: require('@/assets/team/maxim.jpg'), // eslint-disable-line
    post: 'Backend',
  },
  {
    name: 'Михаил Шевельков',
    avatar: require('@/assets/team/mikhail.jpg'), // eslint-disable-line
    post: 'FullStack',
  },
  {
    name: 'Дарья Шабро',
    avatar: require('@/assets/team/dasha.jpg'), // eslint-disable-line
    post: 'UX / UI',
  },
];
