<template>
  <Container :fullWidth="true" class="services">
    <Container :fullWidth="true" class="services__content">
      <Container class="services__title">
        <div class="services__title-left">
          <p>
            Мы собрали основной список решений для вашего проекта.
            Выбирайте, что пригодится вам, а если чего-нибудь не найдёте,
            то напишите нам.
          </p>
        </div>
        <div class="services__title-right">
          <h2 class="title">// УСЛУГИ</h2>
          <CirclesComponent/>
        </div>
      </Container>
      <ServicesList/>
    </Container>
    <Container :fullWidth="true" class="services__other">
      <Container>
        <div class="services__other-left">
          <h3>Не нашли то, что искали?</h3>
          <hr noshade />
          <p>
            <b>Не нашли подходящее под вас решение?</b>
            Мы умеем решать совсем неочевидные задачи и вести разработку не только
            в самых востребованных сферах.
          </p>
        </div>
        <div class="services__other-right">
          <MaskedLink link="tel:89038417828">+7 (903) 841 78-28</MaskedLink>
          <p>или</p>
          <Button @click="showModal">Оставить заявку</Button>
        </div>
      </Container>
    </Container>
  </Container>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';

import { gsapFromTo } from '@/libs/animations';

import ServicesList from '@/components/services/ServicesList.vue';

import Container from '@/components/markup/ContainerComponent.vue';
import CirclesComponent from '@/components/graphics/CirclesComponent.vue';
import MaskedLink from '@/components/MaskedLink.vue';
import Button from '@/components/buttons/ButtonComponent.vue';

export default defineComponent({
  name: 'ServicesComponent',
  components: {
    Container,
    CirclesComponent,
    ServicesList,
    MaskedLink,
    Button,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      gsapFromTo('.services__title-right > .title', { opacity: 0, x: 100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.services__title-right > .title',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.services__title-right > .circles', { opacity: 0, x: 100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.services__title-right > .circles',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.services__title-left > p', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.services__title-left > p',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.services__other-left > h3', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.services__other-left > h3',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.services__other-left > hr', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.services__other-left > hr',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.services__other-left > p', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.services__other-left > p',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.services__other-right', { opacity: 0, x: 100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.services__other-right',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });
    });

    return {
      showModal: () => store.commit('SET_MODAL', true),
    };
  },
});
</script>

<style scoped>
.services {
  margin-top: 100px;
}

.services > .services__content {
  background-color: var(--dark-gray);
}

.services > .services__content .services__title {
  padding-top: 80px;
  padding-bottom: 80px;
}

.services .title {
  margin-bottom: 20px;
}

.services .services__title {
  display: flex;
  align-items: center;
}

.services .services__title > .services__title-left {
  text-align: right;
  font-size: 20px;
  flex: 1;
  padding-right: 100px;
  border-right: 2px solid var(--dark-gray-blue);
}

.services .services__title > .services__title-right {
  flex: 1;
  padding-left: 100px;
}

.services > .services__other {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.services > .services__other > .container {
  display: flex;
  gap: 50px;
}

.services > .services__other .services__other-left {
  flex: 1;
}

.services > .services__other .services__other-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex: 1;
}

.services .services__other h3 {
  font-size: 28px;
  color: var(--blue);
}

.services .services__other hr {
  margin: 15px 0;
  width: 40%;
  border-color: var(--dark-gray-blue);
}

.services .services__other p {
  font-size: 20px;
}

@media screen and (max-width: 1400px) {
  .services .services__title > .services__title-left {
    font-size: 18px;
    padding-right: 70px;
  }

  .services .services__title > .services__title-right {
    padding-left: 70px;
  }

  .services > .services__other .services__other-right {
    gap: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .services > .services__other .services__other-right {
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .services > .services__content .services__title {
    flex-direction: column-reverse;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .services .services__title > .services__title-left,
  .services .services__title > .services__title-right {
    padding: 0;
    text-align: center;
  }

  .services .services__title > .services__title-left {
    border-right: 0;
    margin-top: 20px;
  }

  .services .circles {
    justify-content: center;
  }

  .services > .services__other > .container {
    flex-direction: column;
  }

  .services > .services__other .services__other-left,
  .services > .services__other .services__other-right {
    text-align: center;
    padding: 0;
  }

  .services .services__other hr {
    margin: 15px auto;
  }

  .services > .services__other .services__other-right {
    flex-direction: row;
    gap: 15px;
  }
}

@media screen and (max-width: 490px) {
  .services > .services__other .services__other-right {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}
</style>

<style>
.services .services__other a {
  color: white;
  font-weight: 600;
}

.services .services__other .masked__link-overwrap {
  font-weight: 600;
}
</style>
