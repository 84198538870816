<template>
  <div class="header__background"></div>
</template>

<style scoped>
.header__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/background.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 0;
}

@media screen and (max-width: 1800px) {
  .header__background {
    top: -100px;
  }
}
</style>
