import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';
import HomeView from '@/views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue'),
  },
  {
    path: '/blog/:id',
    name: 'article',
    component: () => import('../views/ArticleView.vue'),
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/AgreementView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
