<template>
  <Container :fullScreen="true" :flex="true" class="home-screen">
    <Slider :interval="8500" />
    <PreparationComponent />
    <HeaderBackground />
  </Container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Container from './markup/ContainerComponent.vue';

import Slider from './slider/SliderComponent.vue';
import PreparationComponent from './header/PreparationComponent.vue';
import HeaderBackground from './header/BackgroundComponent.vue';

export default defineComponent({
  components: {
    Container,
    Slider,
    PreparationComponent,
    HeaderBackground,
  },
});
</script>

<style>
@media screen and (max-width: 1800px) {
  .home-screen {
    height: auto !important;
    min-height: 100vh !important;
  }

  .home-screen > .slider {
    height: 100vh;
  }
}
</style>
