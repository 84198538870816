export default [
  {
    title: 'Разработка сайта',
    icon: 'globe',
    id: 'website',
    about: 'Современный продающий сайт с понятным интерфейсом, уникальным дизайном и идеальным быстродействием.',
  },
  {
    title: 'Мобильное приложение',
    icon: 'mobile',
    id: 'mobile',
    about: 'Кроссплатформенное приложение под iOS и Android на фреймворках React Native или Flutter.',
  },
  {
    title: 'Блокчейн',
    icon: 'cubes',
    id: 'blockchain',
    about: 'Реализуем интеграции в ваш проект, приём платежей, смарт-контракты и любые другие блокчейн-технологии.',
  },
  {
    title: 'Создание чат-бота',
    icon: 'robot',
    id: 'bot',
    about: 'Современные чат-боты – новый виток в улучшении пользовательского опыта. Telegram, WhatsApp, VK и др.',
  },
  {
    title: 'Дизайн',
    icon: 'palette',
    id: 'design',
    about: 'Соблюдаем все критерии современного UX / UI дизайна. Ваш продукт не останется незамеченным.',
  },
  {
    title: 'Десктоп',
    icon: 'laptop-code',
    id: 'desktop',
    about: 'Кроссплатформенное desktop-приложение. Комплексное решение, способное закрыть все бизнес-задачи.',
  },
  {
    title: 'B2B',
    icon: 'briefcase',
    id: 'b2b',
    about: 'Работаем с бизнесами любого уровня, автоматизируем бизнес-процессы. Уникальные B2B решения.',
  },
  {
    title: 'Поддержка',
    icon: 'handshake',
    id: 'support',
    about: 'Техническая поддержка и доработка ваших проектов. Проводим аудиты, даём рекомендации.',
  },
];
