<template>
  <div class="masked__link">
    <a :href="link"><slot></slot></a>

    <a class="masked__link-overwrap" :href="link">
      <slot></slot>
    </a>

    <div class="masked__link-background"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MaskedLink',
  props: {
    link: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
.masked__link {
  width: fit-content;
  position: relative;
  font-size: 24px;
  cursor: pointer;
}

.masked__link > a {
  position: relative;
  z-index: 1;
}

.masked__link > .masked__link-overwrap {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0%;
  height: 100%;
  color: white;
  z-index: 2;
  transition: .15s ease;
}

.masked__link > .masked__link-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--blue);
  z-index: 0;
  transition: .15s ease;
}

.masked__link:hover > .masked__link-overwrap {
  width: 100%;
}

.masked__link:hover > .masked__link-background {
  width: 100%;
}

@media screen and (max-width: 1800px) {
  .masked__link {
    font-size: 20px;
  }
}
</style>
