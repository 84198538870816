export default [
  {
    title: 'Блокчейн по-русски',
    image: require('@/assets/portfolio/jpg/bpr.jpg'), // eslint-disable-line
    type: 'Инфраструктура',
    about: 'Комплекс технологических решений, который включает в себя: P2P обменник криптовалют, сервис приёма криптоплатежей',
  },
  {
    title: 'ФК «Среда»',
    image: require('@/assets/portfolio/jpg/fcsreda.jpg'), // eslint-disable-line
    type: 'Лэндинг',
    about: 'Информационный веб-сайт для футбольной команды «Среда» с формой обратной связи и интегрированным в бэкэнд чат-ботом Telegram.',
  },
  {
    title: 'Академия «MineClub»',
    image: require('@/assets/portfolio/jpg/academy.jpg'), // eslint-disable-line
    type: 'Лэндинг',
    about: 'Сайт онлайн-академии по обучению инвестициям и заработку на криптовалюте и блокчейн-технологиях.<br><br>Помимо лэндинга данный проект включает в себя целую экосистему для обучения с <b>домашними заданиями</b>, <b>ботом</b> и <b>криптоиграми</b>.',
  },
  {
    title: 'Фабрика раскладушек',
    image: require('@/assets/portfolio/jpg/shop.jpg'), // eslint-disable-line
    type: 'Интернет-магазин',
    about: 'Многофункциональный сайт компании «Фабрика раскладушек», структура которого состоит из <b>лэндинга</b> и <b>интернет-магазина</b>.<br><br>На сайте присутствует личный кабинет, интеграция со СДЭК, Тинькофф Кассой и много других функций.',
  },
];
