<template>
  <div class="circles">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
</template>

<style scoped>
.circles {
  display: flex;
  gap: 20px;
}

.circles > .circle {
  --size: 10px;
  width: var(--size);
  height: var(--size);
  background: var(--blue);
  border-radius: 50%;
}
</style>
