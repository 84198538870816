<template>
  <Container :fullWidth="true" class="about">
    <Container>
      <div class="about__header">
        <div class="about__header-left">
          <h2 class="title">// О нас</h2>
          <h3>Умеем, практикуем и любим свою работу</h3>
        </div>
        <div class="about__header-right">
          <CirclesComponent/>
          <p>
            Как единое целое наша команда была сформирована <span>в 2021 году</span>.
            С самого начала мы работаем без привлечения фрилансеров или сторонних студий.
            Мы стараемся строить наши отношения с клиентами на принципах долгосрочного
            сотрудничества, предоставляя качественный сервис и гарантию на свою работу.
          </p>
        </div>
      </div>
      <ul class="about__advantages">
        <li
          v-for="(item, index) in advantages"
          :key="index"
        >
          <span>{{ index + 1 }}</span>
          <p v-html="item" />
        </li>
      </ul>
      <ul class="about__team" id="team">
        <li
          v-for="(item, index) in team"
          :key="index"
          itemscope
          itemtype="http://schema.org/Person"
        >
          <div class="about__team-image">
            <img :src="item.avatar" :alt="item.name">
          </div>
          <h3 itemprop="name">{{ item.name }}</h3>
          <p itemprop="jobTitle">{{ item.post }}</p>
        </li>
      </ul>
    </Container>
  </Container>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import { gsapFromTo } from '@/libs/animations';

import Container from '@/components/markup/ContainerComponent.vue';
import CirclesComponent from '@/components/graphics/CirclesComponent.vue';

import advantages from './content/advantages';
import team from './content/team';

export default defineComponent({
  name: 'AboutComponent',
  components: {
    Container,
    CirclesComponent,
  },
  setup() {
    onMounted(() => {
      gsapFromTo('.about__header-left > .title', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.about__header-left > .title',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.about__header-left > h3', { opacity: 0, x: -100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.about__header-left > h3',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.about__header-right > .circles', { opacity: 0, x: 100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.about__header-right > .circles',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      gsapFromTo('.about__header-right > p', { opacity: 0, x: 100 }, {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: '.about__header-right > p',
          start: 'top bottom',
          end: 'bottom 80%',
          scrub: true,
        },
      });

      const advantagesEl = document.querySelectorAll('.about__advantages > li');
      advantagesEl.forEach((item) => {
        gsapFromTo(item, { opacity: 0, y: 80 }, {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: item,
            start: 'top bottom',
            end: 'bottom 85%',
            scrub: true,
          },
        });
      });

      const teamEl = document.querySelectorAll('.about__team > li');
      teamEl.forEach((item) => {
        gsapFromTo(item, { opacity: 0, y: 80 }, {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: item,
            start: 'top bottom',
            end: 'bottom 85%',
            scrub: true,
          },
        });
      });
    });

    return {
      advantages,
      team,
    };
  },
});
</script>

<style scoped>
.about {
  background: var(--dark-gray);
}

.about > .container {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.about .about__header {
  display: flex;
  align-items: center;
}

.about .about__header > .about__header-left {
  flex: 1;
  text-align: right;
  padding-right: 100px;
}

.about .about__header > .about__header-left > h3 {
  font-size: 36px;
  line-height: 42px;
  margin-top: 20px;
}

.about .about__header > .about__header-right {
  flex: 1;
  padding-left: 100px;
  border-left: 2px solid var(--dark-gray-blue);
}

.about .about__header > .about__header-right > p {
  font-size: 20px;
}

.about ul {
  list-style-type: none;
}

.about .about__advantages {
  display: flex;
  gap: 50px;
  margin: 120px 0;
}

.about .about__advantages > li {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 15px;
}

.about .about__advantages > li > p {
  font-size: 18px;
}

.about .about__advantages > li > span {
  font-size: 96px;
  font-weight: 600;
  line-height: 70px;
  color: var(--blue);
}

.about .about__team {
  display: flex;
  justify-content: space-between;
}

.about .about__team > li {
  max-width: 200px;
  text-align: center;
}

.about .about__team > li > .about__team-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 50%;
  overflow: hidden;
  transition: .15s ease;
  border: 2px solid transparent;
}

.about .about__team > li > .about__team-image > img {
  border-radius: 50%;
  width: 100%;
}

.about .about__team > li > h3 {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.about .about__team > li > p {
  font-weight: 300;
  color: #e8e8e8;
  font-size: 16px;
}

.about .about__team > li:hover > .about__team-image {
  border: 2px solid #508BCB;
}

@media screen and (max-width: 1800px) {
  .about .about__advantages > li > p {
    font-size: 16px;
  }

  .about .about__advantages > li > span {
    font-size: 115px;
  }

  .about .about__advantages > li {
    gap: 8px;
  }

  .about .about__team > li {
    max-width: 170px;
  }
}

@media screen and (max-width: 1400px) {
  .about .about__header > .about__header-left {
    font-size: 18px;
    padding-right: 70px;
  }

  .about .about__header > .about__header-right {
    padding-left: 70px;
  }

  .about .about__header > .about__header-left > h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .about .about__advantages {
    margin: 80px 0;
  }

  .about .about__team > li {
    max-width: 140px;
  }

  .about .about__team > li > h3 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .about .about__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .about .about__header > .about__header-left {
    padding-right: 0;
    text-align: left;
    margin-bottom: 20px;
  }

  .about .about__header > .about__header-left > h3 {
    font-size: 28px;
  }

  .about .about__header > .about__header-right {
    padding-left: 0;
    border: none;
  }

  .about .about__advantages {
    flex-direction: column;
    max-width: 500px;
    margin: 80px auto;
  }

  .about .about__advantages > li > span {
    font-size: 96px;
  }

  .about .about__team {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}

@media screen and (max-width: 800px) {
  .about .about__header {
    align-items: center;
  }

  .about .about__header > .about__header-left,
  .about .about__header > .about__header-right {
    text-align: center;
  }

  .about .circles {
    justify-content: center;
  }

  .about .about__team {
    gap: 40px;
  }
}

@media screen and (max-width: 490px) {
  .about .about__advantages > li {
    justify-content: center;
  }
}
</style>

<style>
.about .about__header > .about__header-right > .circles {
  margin-bottom: 20px;
}
</style>
