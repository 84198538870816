<template>
  <button class="logotype" @click="redirect">
    <div class="logotype__image"><JedelsLogo /></div>
    <div class="logotype__text">
      <p>JEDELS</p>
      <p>TECHNOLOGIES</p>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import JedelsLogo from './LogoSvg.vue';

export default defineComponent({
  components: {
    JedelsLogo,
  },
  setup() {
    const router = useRouter();

    const redirect = () => {
      router.push({ path: '/' });
    };

    return {
      redirect,
    };
  },
});
</script>

<style scoped>
.logotype {
  display: flex;
  width: fit-content;
  justify-items: flex-start;
  align-items: center;
  height: 60px;
  cursor: pointer;
  font-family: var(--customfont);
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.logotype > .logotype__image {
  display: flex;
  align-items: center;
  width: 40px;
  height: 100%;
  fill: white;
}

.logotype > .logotype__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  color: white;
}

.logotype > .logotype__text > p:first-child {
  font-weight: 600;
  font-size: 31px;
  line-height: 24px;
}

.logotype > .logotype__text > p:last-child {
  font-weight: 300;
  font-size: 17px;
  line-height: 14px;
}

@media screen and (max-width: 1800px) {
  .logotype {
    height: 50px;
  }

  .logotype > .logotype__text > p:first-child {
    font-size: 24px;
  }

  .logotype > .logotype__text > p:last-child {
    font-size: 13px;
  }
}
</style>
