<template>
  <div class="phone">
    <div class="phone__screen">
      <div class="phone__logo">
        <JedelsLogotype />
        <h2>JEDELS</h2>
        <h3>TECHNOLOGIES</h3>
      </div>
    </div>

    <div class="phone__buttons">
      <div class="phone__toggle"></div>
      <div class="phone__volume"></div>
      <div class="phone__lock"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import JedelsLogotype from './LogoSvg.vue';

export default defineComponent({
  components: {
    JedelsLogotype,
  },
});
</script>

<style scoped>
.phone {
  --height: 650px;
  --ratio: 0.48276291225;
  --width: calc(var(--height) * var(--ratio));
  --screen-padding: calc(var(--width) * 0.02);
  --radius: 40px;
  --button-width: 5px;
  position: absolute;
  width: var(--width);
  height: var(--height);
  padding: var(--screen-padding);
  top: 50%;
  right: calc(var(--padding-x) * .5 + 100px);
  transform: translateY(-40%);
  border: 2px solid #ececec;
  border-radius: var(--radius);
  background: black;
}

.phone > .phone__screen {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--dark-gray);
  border-radius: calc(var(--radius) - 10px);
}

.phone > .phone__screen::before {
  content: '';
  position: absolute;
  top: var(--screen-padding);
  left: 50%;
  transform: translateX(-50%);
  width: calc(var(--width) * 0.2);
  height: calc(var(--height) * 0.035);
  border-radius: calc(var(--radius) - 10px);
  background: black;
}

.phone > .phone__screen::after {
  content: '';
  position: absolute;
  top: calc(var(--screen-padding) + var(--height) * .0065);
  left: calc(50% + var(--width) * 0.2 / 3.5);
  width: calc(var(--height) * 0.022);
  height: calc(var(--height) * 0.022);
  transform: translateX(-50%);
  border-radius: 50%;
  background: #071023;
}

.phone > .phone__buttons {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.phone > .phone__buttons > .phone__toggle {
  position: absolute;
  top: 18%;
  left: calc(-1 * var(--button-width));
  height: 4%;
  width: var(--button-width);
  background: #dcdcdc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.phone > .phone__buttons > .phone__volume {
  position: absolute;
  top: 25%;
  height: 100%;
  left: calc(-1 * var(--button-width));
  width: var(--button-width);
}

.phone > .phone__buttons > .phone__volume::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 7.5%;
  width: 100%;
  background: #dcdcdc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.phone > .phone__buttons > .phone__volume::after {
  content: '';
  position: absolute;
  top: 8.5%;
  left: 0;
  height: 7.5%;
  width: 100%;
  background: #dcdcdc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.phone > .phone__buttons > .phone__lock {
  position: absolute;
  top: 30%;
  right: calc(-1 * var(--button-width));
  width: var(--button-width);
  height: 11.5%;
  background: #dcdcdc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.phone .phone__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.phone .phone__logo > svg {
  max-width: 100px;
  max-height: 120px;
  fill: white;
}

.phone .phone__logo > h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 28px;
  margin-top: 20px;
}

.phone .phone__logo > h3 {
  font-weight: 300;
  font-size: 20px;
}

@media screen and (max-width: 1800px) {
  .phone {
    --height: 450px;
    --radius: 30px;
    --button-width: 4px;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1000px) {
  .phone {
    display: none;
  }
}
</style>
