<template>
  <button
    :class="{'square': square, 'simple-border': simpleBorder}"
    :disabled="!active"
    v-if="!href"
  >
    <slot v-if="!loading"></slot>
    <VueSpinner v-else />
  </button>
  <a
    :href="href"
    class="button"
    :class="{'square': square, 'simple-border': simpleBorder}" v-else
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
  VueSpinner,
} from 'vue3-spinners';

export default defineComponent({
  name: 'ButtonComponent',
  components: {
    VueSpinner,
  },
  props: {
    square: {
      type: Boolean,
      default: false,
    },
    simpleBorder: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style scoped>
button, a.button {
  position: relative;
  padding: 10px 30px;
  font-family: var(--customfont);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: .5px;
  border: none;
  outline: none;
  color: white;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: var(--blue);
  cursor: pointer;
  transition: .1s ease-out;
}

a.button {
  display: block;
  line-height: normal;
  font-size: 17px;
}

button:disabled {
  opacity: .6;
}

button.square, a.button.square {
  display: block;
  padding: 10px 12px;
  aspect-ratio: 1 / 1;
}

button.simple-border, a.button.simple-border {
  border-radius: 10px;
}

button:hover, a.button:hover {
  background-color: #4a79ad;
}

button:active, a.button:active {
  background-color: #2c73c0;
}

button:disabled:hover {
  background-color: var(--blue);
}

button:disabled:active {
  background-color: var(--blue);
}

@media screen and (max-width: 1800px) {
  button.square, a.button.square {
    padding: 8px 10px;
  }

  button, a.button {
    font-size: 16px;
    padding: 10px 25px;
  }
}
</style>

<style>
a.button > svg {
  width: auto;
}
</style>
