<template>
  <div class="slider__item">
    <div class="slider__item-content">
      <h2>Создание сайтов</h2>
      <Note class="bg--blue">От 30 000₽</Note>
      <p class="slider__section">
        <font-awesome-icon icon="wrench" />
        <span>Наша студия разрабатывает сайты<br>любого типа и сложности <b>«под ключ»</b>.</span>
      </p>
      <p class="slider__section">
        <font-awesome-icon icon="fingerprint" />
        <span>Делаем уникальные и функциональные сайты<br>без использования конструкторов.</span>
      </p>
      <p class="slider__section">
        <font-awesome-icon icon="gauge-high" />
        <span>Обеспечиваем идеальное быстродействие<br>и удобство сайта на любых устройствах.</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Note from '@/components/NoteComponent.vue';

export default defineComponent({
  name: 'WebsiteSlide',
  components: {
    Note,
  },
});
</script>
