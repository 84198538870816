/* eslint-disable */
import * as api from './feedback.api';

import { notify } from '@kyvg/vue3-notification';

export const create = async (
  name: string,
  phone: string,
  email: string,
  content: string,
) => {
  try {
    const payload = await api.create({
      name, phone, email, content,
    });
    return payload;
  } catch (error) {
    notify({
      title: 'Ошибка',
      text: 'Не удалось отправить форму на сервер. Обновите страницу и попробуйте ещё раз.',
      type: 'error',
      duration: 5000,
    });
    return undefined;
  }
};
