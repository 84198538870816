<template>
  <div class="container" :class="{
    'full-screen': fullScreen,
    'full-width': fullWidth,
    'flex': flex
  }">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContainerComponent',
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 calc(var(--padding-x) * .5);
}

.container.full-screen {
  height: 100vh;
  padding: 0 !important;
  max-width: 100% !important;
}

.container.full-width {
  padding: 0 !important;
  max-width: 100% !important;
}

.container.flex {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 0 var(--padding-x);
  }
}

@media (min-width: 800px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1000px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1720px;
  }
}
</style>
