<template>
  <Container class="portfolio__cases">
    <div
      v-for="(item, index) in cases"
      :key="index"
      class="portfolio__item"
    >
      <div class="portfolio__item-image">
        <img :src="item.image" :alt="item.title">
      </div>
      <div class="portfolio__item-type">
        {{ item.type }}
      </div>
      <div class="portfolio__item-content">
        <h3>{{ item.title }}</h3>
        <div class="portfolio__item-hover" v-html="item.about"></div>
      </div>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import { gsapFromTo } from '@/libs/animations';

import Container from '@/components/markup/ContainerComponent.vue';

import cases from './cases';

export default defineComponent({
  name: 'PortfolioCases',
  components: {
    Container,
  },
  setup() {
    onMounted(() => {
      const items = document.querySelectorAll('.portfolio__item');
      items.forEach((item) => {
        gsapFromTo(item, { opacity: 0, y: 80 }, {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: item,
            start: 'top bottom',
            end: 'bottom 80%',
            scrub: true,
          },
        });
      });
    });

    return {
      cases,
    };
  },
});
</script>

<style scoped>
.portfolio__cases {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-top: -300px;
}

.portfolio__cases > .portfolio__item {
  position: relative;
  height: 450px;
  flex: 0 0 calc(50% - 20px);
  overflow: hidden;
  cursor: pointer;
}

.portfolio__cases > .portfolio__item > .portfolio__item-type {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 2px 8px;
  background: rgba(0, 0, 0, .35);
  color: white;
  font-size: 18px;
  border-radius: 5px;
  z-index: 1;
  transition: opacity .25s ease;
}

.portfolio__cases > .portfolio__item > .portfolio__item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.portfolio__cases > .portfolio__item > .portfolio__item-image > img {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.portfolio__cases > .portfolio__item > .portfolio__item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 85%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .35);
  z-index: 2;
  transition: .25s ease-in-out;
}

.portfolio__cases > .portfolio__item > .portfolio__item-content > h3 {
  display: flex;
  align-items: center;
  height: 15%;
  font-size: 24px;
  padding: 10px 28px;
}

.portfolio__cases > .portfolio__item:hover > .portfolio__item-content {
  top: 0;
  background-color: rgba(0, 0, 0, .5);
}

.portfolio__cases > .portfolio__item:hover > .portfolio__item-type {
  opacity: 0;
}

.portfolio__cases > .portfolio__item > .portfolio__item-content > .portfolio__item-hover {
  padding: 10px 28px;
  font-size: 24px;
}

@media screen and (max-width: 1800px) {
  .portfolio__cases > .portfolio__item {
    height: 400px;
  }

  .portfolio__cases > .portfolio__item > .portfolio__item-content > .portfolio__item-hover {
    font-size: 18px;
  }
}

@media screen and (max-width: 1400px) {
  .portfolio__cases > .portfolio__item {
    height: 250px;
  }

  .portfolio__cases > .portfolio__item > .portfolio__item-type {
    top: 15px;
    left: 15px;
    font-size: 15px;
  }

  .portfolio__cases > .portfolio__item > .portfolio__item-content > h3 {
    font-size: 20px;
    padding: 10px 18px;
  }

  .portfolio__cases > .portfolio__item > .portfolio__item-content > .portfolio__item-hover {
    padding: 10px 18px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .portfolio__cases {
    display: block;
    margin-top: -150px;
  }

  .portfolio__cases > .portfolio__item {
    margin-bottom: 25px;
    height: 300px;
  }

  .portfolio__cases > .portfolio__item:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 490px) {
  .portfolio__cases > .portfolio__item {
    height: 250px;
  }
}
</style>
