<template>
  <main>
    <HomeComponent />
    <section id="portfolio">
      <PortfolioComponent />
    </section>
    <section id="services">
      <ServicesComponent />
    </section>
    <section id="about">
      <AboutComponent />
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import HomeComponent from '@/components/HomeComponent.vue';
import PortfolioComponent from '@/components/portfolio/PortfolioComponent.vue';
import ServicesComponent from '@/components/services/ServicesComponent.vue';
import AboutComponent from '@/components/about/AboutComponent.vue';

import { refresh } from '@/libs/animations';

export default defineComponent({
  name: 'HomeView',
  components: {
    HomeComponent,
    PortfolioComponent,
    ServicesComponent,
    AboutComponent,
  },
  setup() {
    document.title = 'Студия разработки сайтов и мобильных приложений | Jedels Tech.';

    onMounted(() => {
      refresh();
    });
  },
});
</script>
