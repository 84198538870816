import { createStore } from 'vuex';

export default createStore({
  state: {
    modal: false,
  },
  getters: {
    MODAL: (state) => state.modal,
  },
  mutations: {
    SET_MODAL: (state, payload) => {
      const html = document.querySelector('html');
      if (html) {
        if (payload) {
          html.classList.add('modal--opened');
        } else {
          html.classList.remove('modal--opened');
        }
      }
      state.modal = payload;
    },
  },
  actions: {
  },
  modules: {
  },
});
