import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _component_Notifications = _resolveComponent("Notifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view),
      _createVNode(_component_FooterComponent)
    ]),
    _createVNode(_component_ModalComponent),
    _createVNode(_component_Notifications, {
      position: "bottom right",
      classes: "jedels-notify",
      width: "500"
    })
  ]))
}